import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
 
export default function AstroEducation() {
  const horoscopes = [
    {
      id: 1,
      imgSrc:
        "https://astroedui18n-live-f4d80dfc7ba44a6283e91-64c3f57.divio-media.com/images/sun3.2e16d0ba.fill-320x200.jpg",
      title: "Our Star: the Sun",
      description:
        "The Sun or Sol, is the star at the centre of our solar system and is the largest object - about 109 times the diameter of Earth. It contains more than 99.8% of the total mass of the Solar System. Through this collection you can explore and observe the behaviour and characteristics of the Sun",
    },
 
    {
      id: 2,
      imgSrc:
        "https://astroedui18n-live-f4d80dfc7ba44a6283e91-64c3f57.divio-media.com/images/eso1039a.2e16d0ba.fill-320x200.jpg",
      title: "Stars",
      description:
        " Stars are cosmic energy engines that produce heat, light, ultraviolet rays, x-rays, and other forms of radiation. They are composed largely of gas and plasma, a superheated state of matter composed of subatomic particles. Discover various types of stars through this collection.",
    },
 
    {
      id: 3,
      imgSrc:
        "https://astroedui18n-live-f4d80dfc7ba44a6283e91-64c3f57.divio-media.com/images/drawing.2e16d0ba.fill-320x200.jpg",
      title: "Astro-Art",
      description:
        " Science keeps unraveling mysteries of the Universe every day while our imagination plays a big part of the process. From designing aliens to glittering milky way, the collection touches both creativity and knowledge to explore the Universe with hands on activities from low cost materials.",
    },
 
    {
      id: 4,
      imgSrc:
        "https://astroedui18n-live-f4d80dfc7ba44a6283e91-64c3f57.divio-media.com/images/galaxies.2e16d0ba.fill-320x200.jpg",
      title: "Galaxies",
      description:
        "A galaxy is a massive, gravitationally bound system consisting of stars, stellar remnants, an interstellar medium of gas and dust, and dark matter. Roughly one hundred billion galaxies are scattered throughout our observable Universe, each a glorious system that might contain billions of stars.",
    },
 
    {
      id: 5,
      imgSrc:
        "https://astroedui18n-live-f4d80dfc7ba44a6283e91-64c3f57.divio-media.com/images/our-fragile-planet_pEx9EEi.2e16d0ba.fill-320x200.jpg",
      title: "Our Planet",
      description:
        "Explore the environmental issues of Earth, importance of Earth satellites in monitoring climate changes and how studying other planets can help to understand the environmental challenges on Earth.",
    },
 
 
    {
      id: 6,
      imgSrc:
        "https://astroedui18n-live-f4d80dfc7ba44a6283e91-64c3f57.divio-media.com/images/our-wonderful-universe_LkVY2AM.2e16d0ba.fill-320x200.jpg",
      title: "Our Universe",
      description:
        " Our wonderful Universe considers the vastness and beauty of the Universe. The activity collection relates to the knowledge and challenges of human space exploration. ",
    },
 
    {
      id: 7,
      imgSrc:
        "https://astroedui18n-live-f4d80dfc7ba44a6283e91-64c3f57.divio-media.com/images/the_planets_and_their_moons.2e16d0ba.fill-320x200.jpg",
      title: "The Planets & their Moons",
      description:
        "Activity collection by Europlanet: The Solar System, in which we live, consists of our Sun as its central star, eight planets with their moons and several dwarf planets. Explore our the planets and moons of our Solar System and discover the amazing distances and scales in our neighbourhood.",
    },
 
    {
      id: 8,
      imgSrc:
        "https://astroedui18n-live-f4d80dfc7ba44a6283e91-64c3f57.divio-media.com/images/pluto_cutout_2VoQg56.2e16d0ba.fill-320x200.jpg",
      title: "Children's Planetary Maps",
      description:
        "  Activity collection by Europlanet: Using the maps of planets and moons specifically designed for children, students will have an insight into the geography, environmental conditions, astrobiological potential and exploration opportunities of Pluto, Charon, Titan, Io, Moon, Mars and Venus. ",
    },
 
    {
      id: 9,
      imgSrc:
        "https://astroedui18n-live-f4d80dfc7ba44a6283e91-64c3f57.divio-media.com/images/AsteroidsCometsMeteors_xlAsTD3.2e16d0ba.fill-320x200.jpg",
      title: "Asteroids and Meteors",
      description:
        "Discover the differences between asteroids, comets and meteors. Learn how to track an asteroid, count craters on Earth and build your own asteroid in this collection of activities. Acknowledgements: many activities of this collection are by Europlanet. ",
    },
 
    {
      id: 10,
      imgSrc:
        "https://astroedui18n-live-f4d80dfc7ba44a6283e91-64c3f57.divio-media.com/images/1408_materials_poFXRWa.2e16d0ba.fill-320x200.jpg",
      title: " Tactile Astronomy ",
      description:
        " Explore our Solar System through Meet Our Neighbours activity series in tactile form. This collection of activities uses cheap or household items to create a tactile hands-on experience for visually impaired students and their non-visually impaired peers.",
    },
 
    {
      id: 11,
      imgSrc:
        "https://astroedui18n-live-f4d80dfc7ba44a6283e91-64c3f57.divio-media.com/images/STEAM-Med.2e16d0ba.fill-320x200.jpg",
      title: "STEAM-Med project",
      description:
        "The project STEAM-Med is a collective process coordinated by OAE Center Italy aimed at creating codesigned educational paths. The first edition took place in Lampedusa, Sicily, Italy from July 3 to 9, 2022. The activities published in AstroEDU were readapted from the educational paths designed by the NAECs of the countries of the Mediterranean area who participated to this school. For more information about the project",
    },
 
    {
      id: 12,
      imgSrc:
        "https://astroedui18n-live-f4d80dfc7ba44a6283e91-64c3f57.divio-media.com/images/IMG20240904130737-e1729519320826.2e16d0ba.fill-320x200.jpg",
      title: "Sabir",
      description:
        " The Sabir co-design project was developed by the OAE Center Italy aimed at producing activities for high school students. It was conducted first online and ended with an inperson workshop (Milan, 2-6 September 2024). The activities published in AstroEDU are readapted from the Inquiry-Based Learning (IBL) educational paths designed by the NAECs who participated to this school. For more information",
    },
  ];
 
  return (
    <div className="yearly-horoscope container">
      <h1>Astroveda Education</h1>
      <h2>2025</h2>
      <a href="">
        <img
          src="http://localhost:3000/static/media/avg-logo.3ef25c23493ef5225747.jpeg"
          alt="loading...."
        />
      </a>
 
       
      <p>
        It is a long established fact that a reader will be distracted by the
        readable content of a page when looking at its layout. The point of
        using Lorem Ipsum is that it has a more-or-less normal distribution of
        letters, as opposed to using 'Content here, content here', making it
        look like readable English. Many desktop publishing packages and web
        page editors now use Lorem Ipsum as their default model text, and a
        search for 'lorem ipsum' will uncover many web sites still in their
        infancy. Various versions have evolved over the years, sometimes by
        accident, sometimes on purpose (injected humour and the like).
      </p>
 
      <p>
        There are many variations of passages of Lorem Ipsum available, but the
        majority have suffered alteration in some form, by injected humour, or
        randomised words which don't look even slightly believable.
      </p>
 
      <h3 style={{color:"#d73878", fontWeight:"700", textDecoration:"underline"}}>Activity Collections</h3>
 
      <div className="row mt-4 astroveda-education">
        {horoscopes.map((horoscope, index) => (
          <div className="col-xl-4 col-lg-4 col-md-6 col-12 mb-2" key={index}>
            <div className="card">
              <div className="row">
             
                <div className="col-12">
                <h3>{horoscope.title}</h3>
                <img
                    src={horoscope.imgSrc}
                    alt={horoscope.title}
                    loading="lazy"
                  />
                 
                  <h5>
                    {horoscope.description}
                   
                  </h5>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
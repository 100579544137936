import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Logo from "../images/avg-logo.jpeg";
export default function Yearlyhoroscope() {
  const horoscopes = [
    {
      id: 1,
      imgSrc:
        "https://d1gcna0o0ldu5v.cloudfront.net/fit-in/150x150/assets/images/horoscope_page/horoscope-01.webp",
      title: "Aries Yearly Horoscope",
      description:
        "The year 2025 is an exciting and transformative one for Aries. As a fire sign ruled by Mars, you thrive on energy, action, and ambition, and this year will push you to channel those traits into meaningful growth. The planetary alignments indicate opportunities to grow personally, professionally, and emotionally, with...",
    },
 
    {
      id: 2,
      imgSrc:
        "https://d1gcna0o0ldu5v.cloudfront.net/fit-in/150x150/assets/images/horoscope_page/horoscope-02.webp",
      title: "Taurus Yearly Horoscope",
      description:
        " The year 2025 is set to be a year of stability, growth, and emotional fulfillment for Taurus. Governed by Venus, the planet of love and abundance, you will find yourself drawn to pursuits that bring beauty and harmony into your life. This year encourages you to build on the foundations you have already set, while als... ",
    },
 
    {
      id: 3,
      imgSrc:
        "https://d1gcna0o0ldu5v.cloudfront.net/fit-in/150x150/assets/images/horoscope_page/horoscope-03.webp",
      title: "Gemini Yearly Horoscope",
      description:
        " The year 2025 promises to be a dynamic and transformative period for Gemini. Ruled by Mercury, the planet of communication and intellect, your natural curiosity and adaptability will be your greatest strengths. This year encourages you to embrace change, explore new opportunities, and strengthen your connections with...  ",
    },
 
    {
      id: 4,
      imgSrc:
        "https://d1gcna0o0ldu5v.cloudfront.net/fit-in/150x150/assets/images/horoscope_page/horoscope-04.webp",
      title: "Cancer Yearly Horoscope",
      description:
        "  The year 2025 is set to be a year of emotional growth, self-discovery, and meaningful connections for Cancer. As a water sign ruled by the Moon, you are deeply intuitive and nurturing by nature. This year encourages you to trust your instincts and focus on building a life that aligns with your emotional and spiritual...  ",
    },
 
    {
      id: 5,
      imgSrc:
        "https://d1gcna0o0ldu5v.cloudfront.net/fit-in/150x150/assets/images/horoscope_page/horoscope-05.webp",
      title: "Leo Yearly Horoscope",
      description:
        " The year 2025 is set to be a powerful and transformative year for Leo. As a fire sign ruled by the Sun, you thrive on ambition, creativity, and self-expression. This year will provide you with opportunities to shine and make significant strides in various aspects of your life. However, it also calls for introspection... ",
    },
 
    {
      id: 6,
      imgSrc:
        "https://d1gcna0o0ldu5v.cloudfront.net/fit-in/150x150/assets/images/horoscope_page/horoscope-06.webp",
      title: "Virgo Yearly Horoscope",
      description:
        "  The year 2025 promises a transformative journey for Virgo, with opportunities to grow, learn, and solidify your foundations. As an Earth sign ruled by Mercury, your analytical skills and meticulous nature will be your greatest assets this year. Expect significant progress in your personal and professional life as you... ",
    },
 
    {
      id: 7,
      imgSrc:
        "https://d1gcna0o0ldu5v.cloudfront.net/fit-in/150x150/assets/images/horoscope_page/horoscope-07.webp",
      title: "Libra Yearly Horoscope",
      description:
        " The year 2025 brings a harmonious blend of opportunities and challenges for Libra, encouraging you to embrace balance and adaptability. As an Air sign ruled by Venus, you are naturally drawn to beauty, relationships, and fairness. This year, your focus will shift toward achieving personal growth, strengthening connec... ",
    },
 
    {
      id: 8,
      imgSrc:
        "https://d1gcna0o0ldu5v.cloudfront.net/fit-in/150x150/assets/images/horoscope_page/horoscope-08.webp",
      title: "Scorpio Yearly Horoscope",
      description:
        "  The year 2025 promises to be a transformative and rewarding year for Scorpio, with opportunities to harness your natural intensity and focus to achieve your goals. As a Water sign ruled by Mars and Pluto, you are known for your determination, resilience, and deep emotional insight. This year invites you to embrace gr...  ",
    },
 
    {
      id: 9,
      imgSrc:
        "https://d1gcna0o0ldu5v.cloudfront.net/fit-in/150x150/assets/images/horoscope_page/horoscope-09.webp",
      title: "Sagittarius Yearly Horoscope",
      description:
        " The year 2025 brings an exciting mix of opportunities, challenges, and personal growth for Sagittarius. As a fire sign ruled by Jupiter, you are naturally adventurous, optimistic, and philosophical. This year invites you to expand your horizons, whether through travel, learning, or pursuing your ambitions. However, i... ",
    },
 
    {
      id: 10,
      imgSrc:
        "https://d1gcna0o0ldu5v.cloudfront.net/fit-in/150x150/assets/images/horoscope_page/horoscope-10.webp",
      title: "Capricorn Yearly Horoscope",
      description:
        " The year 2025 is set to be a year of growth, stability, and self-discovery for Capricorn. As an Earth sign ruled by Saturn, your natural discipline and perseverance will guide you through both opportunities and challenges. This year, you&rsquo;ll find yourself focusing on long-term goals, strengthening relationships,... ",
    },
 
    {
      id: 11,
      imgSrc:
        "https://d1gcna0o0ldu5v.cloudfront.net/fit-in/150x150/assets/images/horoscope_page/horoscope-11.webp",
      title: "Aquarius Yearly Horoscope",
      description:
        " The year 2025 is set to be an exciting and transformative year for Aquarius. As an Air sign ruled by Uranus and Saturn, you thrive on innovation, independence, and a desire to create positive change. This year invites you to explore new opportunities, embrace personal growth, and strengthen meaningful connections.... ",
    },
 
    {
      id: 12,
      imgSrc:
        "https://d1gcna0o0ldu5v.cloudfront.net/fit-in/150x150/assets/images/horoscope_page/horoscope-12.webp",
      title: "Pisces Yearly Horoscope",
      description:
        "  The year 2025 is set to be a year of emotional growth, creativity, and meaningful connections for Pisces. As a Water sign ruled by Jupiter and Neptune, you are naturally intuitive, compassionate, and imaginative. This year invites you to embrace your unique qualities, explore new opportunities, and strengthen the bon...  ",
    },
  ];
 
  return (
    <div className="yearly-horoscope container">
      <h1>Yearly Horoscope</h1>
      <h2>2025</h2>
      <a href="">
        <img
          src={Logo}
          alt="loading...."
        />
      </a>
 
      <p>
        The new year brings a lot of new opportunities for each and every one of
        us. But how do we know that the opportunity is actually present for us?
        Well, the simplest way to find out is to read your yearly horoscope. The
        yearly horoscope at Astrotalk is the result of collaboration among the
        most experienced and learned Vedic astrologers who put their skills to
        study planets, their movements and thus are able to cater how those
        movements will affect one's life through yearly horoscope. The yearly
        horoscope is prepared in such a way that it gives you a picture of how
        your life and its different aspects such as love, marriage, career, etc.
        would change in the year to come. And once you have that knowledge for
        yourself, you can make choices based on the same.
      </p>
 
      <p>
        From Aries to Pisces and everything in between, your yearly horoscope is
        a guide that helps you stay a step ahead of others in the room. Your
        horoscope especially comes in handy if you are planning to take a big
        step in your life as it can guide you in that endeavour. So, what are
        you waiting for? Read your yearly horoscope today.
      </p>
 
      <div className="row mt-4">
        {horoscopes.map((horoscope, index) => (
          <div className="col-xl-6 col-lg-6 col-md-6 col-12 mb-2" key={index}>
            <div className="card">
              <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                  <img
                    src={horoscope.imgSrc}
                    alt={horoscope.title}
                    loading="lazy"
                  />
                </div>
                <div className="col-xl-9 col-lg-9 col-md-8 col-12">
                  <h3>{horoscope.title}</h3>
                  <h5>
                    {horoscope.description}
                    <Typography
                      variant="body2"
                      component={Link}
                      to='/aries'
                      className="readMore"
                    >
                      read more
                    </Typography>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
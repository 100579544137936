export const monthlyData = [
    {
        m: "https://tak-astrovedaGuru-av.s3.ap-south-1.amazonaws.com/images/report/20210827094937-monthly_horoscope.webp",
        n: "Montholy Horoscope",
        descri: "Monthly horoscope report is based on your sookshma & prana dasas, gives predictions r...",
        r: " 350/-",
        b: "Buy Now"
    },
    {
        m: "https://tak-astrovedaGuru-av.s3.ap-south-1.amazonaws.com/images/report/20210827095412-horoscope_compatibility.webp",
        n: "Horoscope Compatibility",
        descri: "This horoscope report analyses your 7th house in detail to give answers to your queri...",
        r: "399/-",
        b: "Buy Now"
    },
    {
        m: "https://tak-astrovedaGuru-av.s3.ap-south-1.amazonaws.com/images/report/20210827095259-numerology_report.webp",
        n: "Numerology Report",
        descri: "What significant role do numbers play in your life? The report gives you numerology b...",
        r: "499/-",
        b: "Buy Now"
    },
    {
        m: "https://tak-astrovedaGuru-av.s3.ap-south-1.amazonaws.com/images/report/20210827095322-gem_recommendation_report.webp",
        n: "Gem Recommendation Report",
        descri: "This Gem Recommendation Report studies your birth chart and suggests the use of speci...",
        r: "499/-",
        b: "Buy Now"
    },
    {
        m: "https://tak-astrovedaGuru-av.s3.ap-south-1.amazonaws.com/images/report/20210827094736-in_depth_horoscope.webp",
        n: "In-depth Horoscope",
        descri: "In-depth Horoscope will give you detailed life prediction reports and remedieson ever...",
        r: "599/-",
        b: "Buy Now"
    },
    {
        m: "https://tak-astrovedaGuru-av.s3.ap-south-1.amazonaws.com/images/report/20210827095146-education_horocope.webp",
        n: "Education Horoscope",
        descri: "This Education Horoscope guides you regarding your academic growth and helps you with...",
        r: "599/-",
        b: "BuyNow"
    },
    {
        m: "https://tak-astrovedaGuru-av.s3.ap-south-1.amazonaws.com/images/report/20210827095548-rahu_ketu_transit_prediction.webp",
        n: "Rahu Ketu Transit Predictions",
        descri: "The Vedic Astrology planets Rahu and Ketu changes can influence the matters in your c...",
        r: "699/-",
        b: "Buy Now"
    },
    {
        m: "https://tak-astrovedaGuru-av.s3.ap-south-1.amazonaws.com/images/report/20210827095438-saturn_transit_prediction.webp",
        n: "saturn Transit Predictions",
        descri: "With the help of detailed Saturn Transit Reports, know the effect of the sign change",
        r: "799/-",
        b: "Buy Now"
    },
    {
        m: "https://lallantop-media-files.s3.ap-south-1.amazonaws.com/1670321001174MicrosoftTeams-image%20%283%29.png",
        n: "Yearly Horoscope 2025",
        descri: "Get an insight into different aspects of your life this year with your personalised ...",
        r: "799/-",
        b: "Buy Now"
    },
    {
        m: "https://tak-astrovedaGuru-av.s3.ap-south-1.amazonaws.com/images/report/20210827095518-jupiter_transit_prediction.webp",
        n: "jupiter Transit Predictions 2024-25",
        descri: "Jupiter is the sign of finances, emotional issues, joint ventures, mysticism, mystics...",
        r: "1099/-",
        b: "Buy Now"
    },
    {
        m: "https://tak-astrovedaGuru-av.s3.ap-south-1.amazonaws.com/images/report/20210827095107-career_and_business_horoscope.webp",
        n: "Career and bussiness Horoscope",
        descri: "Your success in career and business relies on what decisions you make when face-to-fa...",
        r: "1499/-",
        b: "Buy Now"
    },
    {
        m: "https://tak-astrovedaGuru-av.s3.ap-south-1.amazonaws.com/images/report/20210827095212-wealth_and_fortune_horoscope.webp",
        n: "Wealth and fortune Horoscope",
        descri: "This horoscope report analyses your 2nd and 11th houses in detail to tell about your ...",
        r: "1499/-",
        b: "Buy Now"
    }
];